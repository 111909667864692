// Bootstrap CSS
@use "../node_modules/bootstrap/dist/css/bootstrap.css";
@use "../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@font-face {
  font-family: "ZillaSlRegular";
  src: url("fonts/Zilla Sl/ZillaSlab-Regular.ttf");
}
@font-face {
  font-family: "ZillaSlRsemiBold";
  src: url("fonts/Zilla Sl/ZillaSlab-Bold.ttf");
}

/* SCSS HEX */
$oxfordBlue: #0a2239;
$moonStone: #53a2be;
$Blue: #1d84b5;
$gunmetal: #132e32;
$LapisLazuli: #176087;
$white: #fbfeff;

/*animation*/
.main {
  height: 100vh;
  position: relative;
  overflow: visible;
}
.onePage {
  min-height: 100%;
  overflow: hidden;
}

@media screen {
  :root {
    --grid-columns: 8;
  }
}
.content {
  position: absolute;
  top: 0;
}
.subtitle {
  font-family: "ZillaSlRegular";
  align-self: center;
  text-align: center;
  grid-column: 4/10;
  grid-row: 1/2;
  font-size: 7vw;
  @media screen and (min-width: 1024px) {
    font-size: 5vw;
  }
}
#contact {
  position: absolute;
  width: 100%;
  display: block;
  overflow: auto;
  padding-top: 3vh;
  padding-bottom: 0vh;
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  grid-template-rows: repeat(12, 8.3vh);

  background-color: $oxfordBlue;
  .sayHiContainer {
    grid-column: 1/8;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    p {
      font-family: "ZillaSlRegular";

      grid-column: 1/12;
      text-align: center;
      font-size: 14vh;
      @media screen and (min-width: 1024px) {
        font-size: 16.5vh;
      }
    }
    div:nth-child(1) {
      color: $LapisLazuli;
    }
    div:nth-child(2) {
      color: $moonStone;
    }
    div:nth-child(3) {
      color: $white;
    }
  }
  .contactIcon {
    grid-row: 11/12;
    :hover {
      color: $moonStone;
      cursor: pointer;
    }
  }
  a {
    color: $white;
  }
  .linkedin {
    text-align: start;
    color: $white;
    font-size: 5vw;
    grid-column: 3/4;
  }
  .envelope {
    text-align: end;
    color: $white;
    font-size: 5vw;
    grid-column: 5/6;
  }
  .github {
    text-align: center;
    color: $white;
    font-size: 5vw;
    grid-column: 4/5;
  }
}
#footer {
  position: relative;

  height: 100vh;
  width: 100%;
}
#disclaimer {
  width: 100%;

  position: absolute;
  font-family: "ZillaSlRegular";
  color: $white;
  bottom: 0vh;

  p {
    margin: 0;
    font-size: 0.8rem;
    text-align: center;
    color: $LapisLazuli;
  }
}
