@use "../index.scss";
/* SCSS HEX */
$oxfordBlue: #0a2239;
$moonStone: #53a2be;
$Blue: #1d84b5;
$gunmetal: #132e32;
$LapisLazuli: #176087;
$white: #fbfeff;

.Bar {
  position: sticky;
  display: flex;
  width: 100%;
  justify-content: end;
  top: 0;
  z-index: 99;
  text-decoration: none;
  a {
    color: $gunmetal;
    font-family: "ZillaSlRegular";
    text-decoration: none;
    margin: 1vw;
    font-size: 1rem;
    align-self: center;
    &:hover {
      color: $moonStone;
      font-size: 1.3rem;
      text-shadow: 2px 2px 0px $white, 5px 4px 2px rgba(0, 0, 0, 0.15);
      text-decoration: none;
    }
  }
}
