@use "../index.scss";

/* SCSS HEX */
$oxfordBlue: #0a2239;
$moonStone: #53a2be;
$Blue: #1d84b5;
$gunmetal: #132e32;
$LapisLazuli: #176087;
$white: #fbfeff;

#greeting {
  display: grid;
  gap: 0px 0px;
  grid-template-columns: repeat(var(--grid-columns), 1fr);

  grid-template-rows: 10vh 10vh 30vh 30vh 10vh 10vh;

  * {
    padding: 0px;
  }
}
@keyframes Welcome {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    transform: translateX(0%);
    opacity: 1;
  }
}
#home {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.greeting {
  grid-column: 1/8;
  grid-row: 3/5;
  overflow: auto;
  place-self: center start;
  line-height: normal;
  margin-left: 2vw;

  .hi {
    font-size: 2.5vw;
    font-family: "ZillaSlRegular";
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $oxfordBlue;
    color: $white;
    opacity: 0;
    @media screen and (max-width: 414px) {
      font-size: 4vw;
      margin-bottom: 2%;
    }
  }

  .name {
    font-size: 15vw;
    font-family: "ZillaSlRegular";
    text-shadow: 2px 2px 0px $white, 5px 4px 2px rgba(0, 0, 0, 0.15);
    opacity: 0;

    z-index: 2;
    color: $moonStone;
    @media screen and (max-width: 414px) {
      font-size: 17vw;
      margin-bottom: 2%;
    }
  }

  @for $i from 1 through 4 {
    > :nth-child(#{$i}) {
      animation-name: Welcome;
      animation-duration: 2.5s;
      animation-delay: #{($i + -1) * 0.8}s;
      animation-fill-mode: forwards;
      animation-timing-function: ease-in-out;
    }
  }
}
@keyframes WelcomeImg {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
.selfie {
  grid-column: 4/9;
  grid-row: 3/7;
  place-self: end;
  height: 100vh;
  width: 100%;
  margin-left: 0px;

  display: grid;
  position: relative;
  gap: 0px 0px;
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(1, 1fr);

  img {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: -1;
    margin-left: 2vw;

    max-height: 90vh;
    animation-name: WelcomeImg;
    animation-duration: 2.5s;
    object-fit: contain;

    animation-fill-mode: forwards;
    animation-timing-function: ease-in-out;
  }
}

.selfieBackground {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -2;
  width: 100%;
  height: 40%;
  object-fit: contain;
  border-radius: 10% 0px 0px 0px;
  background-color: $oxfordBlue;
  animation-name: WelcomeImg;
  animation-duration: 2.5s;
  object-fit: contain;
  max-height: 40%;

  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}
