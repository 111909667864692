@use "../index.scss";
// Bootstrap CSS
@use "../../node_modules/bootstrap/dist/css/bootstrap.css";
@use "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@font-face {
  font-family: "ZillaSlRegular";
  src: url("../fonts/Zilla Sl/ZillaSlab-Regular.ttf");
}
@font-face {
  font-family: "ZillaSlRsemiBold";
  src: url("../fonts/Zilla Sl/ZillaSlab-Bold.ttf");
}

/* SCSS HEX */
$oxfordBlue: #0a2239;
$moonStone: #53a2be;
$Blue: #1d84b5;
$gunmetal: #132e32;
$LapisLazuli: #176087;
$white: #fbfeff;

$carousel-indicator-active-bg: $oxfordBlue;
.carousel {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.carousel-title {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 2;

  & .subtitle {
    padding: 10px 20px;
    border-radius: 5px;
    display: inline-block;
    color: $white;
    text-shadow: 3px 0px 0px $oxfordBlue;
    text-shadow: 2.5px 0px 0px $oxfordBlue, 5px 4px 2px rgba(0, 0, 0, 0.15);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $oxfordBlue;
  }
  @media screen and (max-width: 786px) {
    height: auto;
  }
}

.carousel-inner,
.carousel-item {
  height: 100%;
}

.carousel-item {
  position: relative;
}

.main-image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-image {
  max-width: 100%;
  max-height: 100vh;
  object-fit: cover;
}

.carousel-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 1rem;
  z-index: 1;
}

.thumbnail-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.thumbnail {
  width: 80px;
  height: 80px;
  object-fit: cover;
  cursor: pointer;
  border: 2px solid transparent;
  transition: border-color 0.3s ease;
}

.thumbnail.active {
  filter: drop-shadow(0px 0px 10px $oxfordBlue);
}

.description {
  text-align: center;
  color: $oxfordBlue;
  font-family: "ZillaSlRegular";
}

.carousel-control-prev,
.carousel-control-next {
  width: 5%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.carousel-indicators {
  margin-bottom: 0px !important;
  bottom: 0px;

  z-index: 2;
  button {
    background-color: $oxfordBlue !important;
  }
}
