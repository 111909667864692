@use "../index.scss";
// Bootstrap CSS
@use "../../node_modules/bootstrap/dist/css/bootstrap.css";
@use "../../node_modules/bootstrap-icons/font/bootstrap-icons.scss";
@font-face {
  font-family: "ZillaSlRegular";
  src: url("../fonts/Zilla Sl/ZillaSlab-Regular.ttf");
}
@font-face {
  font-family: "ZillaSlRsemiBold";
  src: url("../fonts/Zilla Sl/ZillaSlab-Bold.ttf");
}

/* SCSS HEX */
$oxfordBlue: #0a2239;
$moonStone: #53a2be;
$Blue: #1d84b5;
$gunmetal: #132e32;
$LapisLazuli: #176087;
$white: #fbfeff;

/* Job Icon*/
$iconpos-X: -75%;
$iconpos-sm-X: -85%;
$iconpos-Y: 70%;
$iconpos-sm-Y: 50%;

#skills {
  grid-template-columns: repeat(12, minmax(0, 1fr));
  grid-template-rows: repeat(13, 7.7vh);
  .subtitle {
    font-family: "ZillaSlRegular";
    align-self: center;
    text-align: center;

    font-size: 7vw;
    @media screen and (min-width: 1024px) {
      font-size: 5vw;
    }
  }

  .subtitle {
    color: $white;
    text-shadow: 3px 0px 0px $oxfordBlue;
    text-shadow: 2.5px 0px 0px $oxfordBlue, 5px 4px 2px rgba(0, 0, 0, 0.15);
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $oxfordBlue;
  }
  @media screen and (max-width: 786px) {
    height: auto;
  }
}
.cardGroup {
  padding-left: 1vw;
  padding-right: 1vw;
  width: 100vw;
  max-height: none;
  place-self: center;
  margin: auto;

  @media screen and (min-width: 1024px) {
    max-width: 100vh;
  }
}

.square {
  position: relative;
  margin: auto;

  &:after {
    content: "";
    display: block;
    padding-top: 100%;
  }
}
.cardContent {
  position: absolute;
  width: 100%;
  height: 100%;
  max-width: 50vh;
  max-height: 50vh;
  margin: auto;
}
.bufferRow {
  @media screen and (min-width: 768px) {
    display: none;
  }
}

.front {
  p {
    font-family: "ZillaSlRegular";
    text-align: center;
    color: $oxfordBlue;

    font-size: 4vw;
    @media screen and (min-width: 1024px) {
      font-size: 2vw;
    }
    @extend .my-2;
  }
  img {
    display: block;
    margin: auto;
    max-height: 80%;
    padding-bottom: 0px;
  }

  background-color: $white;
  border-style: solid;
  border-width: 1px;
  border-radius: 3vw;
  box-shadow: 2px 2px 0px $oxfordBlue, 3px 3px 0px $oxfordBlue,
    4px 4px 0px $oxfordBlue, 5px 4px 2px rgba(0, 0, 0, 0.15);
}
#webDev > .back {
  img {
    -webkit-transform: translateX(-60%) translateY($iconpos-Y);
    transform: translateX(-60%) translateY($iconpos-Y);
    @media screen and (max-width: 768px) {
      -webkit-transform: translateX(-60%) translateY($iconpos-sm-Y);
      transform: translateX(-60%) translateY($iconpos-sm-Y);
    }
  }
}
.back {
  overflow: hidden;
  p {
    font-family: "ZillaSlRegular";
    text-align: center;
    color: $white;

    font-size: 3vw;
    @media screen and (min-width: 1024px) {
      font-size: 2vw;
    }
    @extend .my-2;
  }
  img {
    display: block;
    margin: auto;
    max-height: 50%;
    padding-bottom: 0px;
    -webkit-transform: translateX($iconpos-sm-X) translateY($iconpos-Y);
    transform: translateX($iconpos-X) translateY($iconpos-Y);
    @media screen and (max-width: 768px) {
      -webkit-transform: translateX($iconpos-sm-X) translateY($iconpos-sm-Y);
      transform: translateX($iconpos-sm-X) translateY($iconpos-sm-Y);
    }
  }
  ul {
    position: absolute;
    list-style-type: none;
    color: $moonStone;
    top: 3rem;
    right: 1rem;
    text-align: right;
    font-family: "ZillaSlRegular";
    font-size: 5vw;
    @media screen and (max-width: 480px) {
      font-size: 10vw;
    }
    @media screen and (max-width: 768px) and (min-width: 481px) {
      font-size: 4.5vw;
    }
    @media screen and (max-width: 769px) and (min-width: 1023px) {
      font-size: 1vw;
    }
    @media screen and (min-width: 1024px) {
      font-size: 3vw;
    }
    li {
      &:hover,
      &:active {
        font-family: "ZillaSlRsemiBold";
        color: $white;
      }
    }
  }
  background-color: $oxfordBlue;
  border-style: solid;
  border-width: 0px;
  border-radius: 3vw;
  box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
}
.mirror {
  @media screen and (min-width: 768px) {
    .front {
      img {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
      }
    }
    .back {
      img {
        -webkit-transform: scaleX(-1) translateX($iconpos-X)
          translateY($iconpos-Y);
        transform: scaleX(-1) translateX($iconpos-X) translateY($iconpos-Y);
      }
      ul {
        text-align: left;
        left: 0rem;
      }
    }
  }
}

#contentCreator,
#businessStud {
  @extend .mirror;
}

.flip {
  position: relative;
  > .front,
  > .back {
    display: block;
    transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    transition-duration: 0.5s;
    transition-property: transform, opacity;
  }
  > .front {
    transform: rotateY(0deg);
  }
  > .back {
    position: absolute;
    opacity: 0;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: rotateY(-180deg);
  }
  &:hover,
  &:active {
    > .front {
      opacity: 0;
      transform: rotateY(180deg);
    }
    > .back {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
}
