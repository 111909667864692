@use "../index.scss";

/* SCSS HEX */
$oxfordBlue: #0a2239;
$moonStone: #53a2be;
$Blue: #1d84b5;
$gunmetal: #132e32;
$LapisLazuli: #176087;
$white: #fbfeff;

#journey {
  position: relative;
  display: block;
  z-index: 0;
  padding-top: 3vh;
  padding-bottom: 3vh;
  margin-top: 3vh;

  .journey-container {
    position: relative;
    display: block;
    width: 100vw;
    height: auto;
    margin: 0px;
    padding: 0px;
  }
  .stickyelement {
    z-index: 1;
    height: auto;
  }
  .subtitle-container {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-bottom: 85vh;
    z-index: 2;
    .subtitle {
      position: sticky;
      top: 0px;

      color: $oxfordBlue;
      text-shadow: 2.5px 0px 0px $white, 3px 0px 0px $white,
        4px 0px 0px $oxfordBlue, 4.5px 0px 0px $oxfordBlue;
      -webkit-text-stroke-width: 1px;
      -webkit-text-stroke-color: $oxfordBlue;
    }
  }

  .nav-container {
    position: absolute;
    display: block;
    height: 100%;
    padding-top: 45vh;
    padding-bottom: 45vh;
    @media screen and (max-width: 430px) {
      /* Styles for mobile devices */
      display: none;
    }

    nav {
      position: sticky;
      width: 100vw;
      top: 45%;
      left: 1vw;
      z-index: 1;
    }
    ul {
      list-style-type: none;
    }
    li {
      font-family: "ZillaSlRsemiBold";

      text-shadow: 1px 0px 0px $oxfordBlue, 1.5px 0px 0px $oxfordBlue;
      -webkit-text-stroke-width: 0.5px;
      -webkit-text-stroke-color: $oxfordBlue;
    }
    li.active {
      color: $oxfordBlue;
      /* Mobile devices */
      /* Tablets */
      @media screen and (min-width: 481px) and (max-width: 1024px) {
        /* Styles for tablets */
        font-size: 2vw;
      }

      /* Laptops and Desktops */
      @media screen and (min-width: 1025px) and (max-width: 1920px) {
        /* Styles for laptops and desktops */
        font-size: 1.5vw;
      }
    }
    li.inactive {
      color: $white;
      @media screen and (min-width: 481px) and (max-width: 1024px) {
        /* Styles for tablets */
        font-size: 1.5vw;
      }

      /* Laptops and Desktops */
      @media screen and (min-width: 1025px) and (max-width: 1920px) {
        /* Styles for laptops and desktops */
        font-size: 1vw;
      }
    }
  }

  .JourneycardList {
    position: relative;

    flex-direction: column;

    margin-right: 0px;
    width: 100%;
    z-index: 0;

    .img-container {
      width: 100%;
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      right: 0px;
      left: 0px;
      margin: 0px;
      padding-bottom: 10vh;
      #bg {
        width: 60%;
        align-self: start;
        position: absolute;
        margin-bottom: 25vh;
        margin-left: 1vw;
        z-index: -1;
        display: none;
        filter: opacity(0.5);
        @media screen and (max-width: 430px) {
          margin-bottom: 0px;
        }
        @media screen and (min-width: 1024px) {
          width: 40%;
          margin-bottom: 20vh;
        }
      }
      #job,
      #title,
      #period {
        display: none;
        font-family: "ZillaSlRsemiBold";
        color: $oxfordBlue;
        align-self: end;
        margin-right: 1vh;
        margin-bottom: 0px;
        padding-right: 2vh;
      }
      #job {
        width: 50%;
        @media screen and (max-width: 430px) {
          /* Styles for mobile devices */

          min-height: 60%;
          width: 90%;
        }

        font-size: 1rem;

        margin-bottom: 4vh;
        vertical-align: middle;
        p {
          white-space: pre-wrap;
        }
        align-self: flex-end;
      }
      #title {
        font-size: 2rem;
        align-self: flex-end;
        text-align: right;
      }
      #period {
        font-size: 1rem;
        align-self: flex-end;
      }
      @media screen and (max-width: 430px) {
        /* Styles for laptops and desktops */

        #title {
          font-size: 1rem;
        }
      }
      @media screen and (min-width: 1025px) {
        /* Styles for laptops and desktops */
        p {
          font-size: 1rem;
        }
        #title {
          font-size: 1.5rem;
        }
      }
      #logo {
        align-self: flex-end;
        display: block;
        margin: 1vh;
        width: 50%;
        height: auto;
        padding: 1vh;
        z-index: 0;
      }
    }
    @keyframes textappear {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }

    .activeView {
      #bg,
      #job,
      #title,
      #period {
        display: block;
        transition: all 3s;
        animation-name: textappear;
        animation-duration: 3s;
      }
      #job {
        display: inline-block;
      }
      justify-content: end;
      #logo {
        transition: 1s;
        width: 30%;
        bottom: 10vh;
        @media screen and (min-width: 1024px) {
          /* Styles for laptops and desktops */
          width: 20%;
        }
        @media screen and (max-width: 1024px) {
          /* Styles for laptops and desktops */
          width: 40%;
        }
      }
    }
  }
  .journeySubstitle {
    background-color: $moonStone;
    font-family: "ZillaSlRegular";
  }
}
